@import 'jekyll-theme-isotc211';

// Asciidoctor-generated HTML styling


body.home {
  section.resource-grid {
    padding: 1em 15vw 2em 15vw;

    @media screen and (min-width: $bigscreenBreakpoint) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
      padding: 1em 5vw 2em 15vw;
    }
    @media screen and (min-width: $widescreenBreakpoint) {
      padding: 1em 15vw 2em 15vw;
    }

    > .resource {
      box-sizing: border-box;
      flex-basis: 50%;
      display: flex;
      flex-flow: column nowrap;
      padding-right: 1em;

      ul {
        padding-left: 1em;
      }

      .wg {
        margin-top: -1em;

        abbr {
          text-decoration: none;
          cursor: help;
        }
      }

      h3 {
        font-size: 160%;
        font-weight: normal;

        .new-label {
          color: $importantColor;
          font-size: 1rem;
          letter-spacing: -.04em;
        }
      }

      .main-links {
        flex: 1;
        a {
          font-weight: bold;
        }
      }
    }
  }
}

body.xml-schemas {
  @extend .pad-all-main-contents;
}

// Flexbox corrections for IE 9-10

@media screen and (min-width: 0\0) {

  body.home section.resource-grid > .resource {
    width: 31%;
    display: block;
    float: left;
  }

  body.home section.resource-grid {
    clear: both;
    display: block;
  }
}

// Flexbox corrections for IE 11

@media all and (-ms-high-contrast:none) {
  body.home section.resource-grid > .resource{
    display: inline-block;
  }
}
